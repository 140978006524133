<script setup lang="ts">
import DJIcon from '~/components/ui/icon/Icon.vue'

defineOptions({
  name: 'DJButton',
})
defineProps({
  /**
   * @description loading the button
   */
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * @description button size
   */
  size: {
    type: String,
    values: buttonSizes,
    default: '',
  },
  /**
   * @description disable the button
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * @description button type
   */
  type: {
    type: String,
    values: buttonTypes,
    default: '',
  },
  /**
   * @description determine whether it's a round button
   */
  rounded: {
    type: Boolean,
    default: false,
  },
  /**
   * @description icon component
   */
  icon: {
    type: Object,
    default: null,
  },
  /**
   * @description prefix icon component
   */
  prefixIcon: {
    type: Object,
    default: null,
  },
  /**
   * @description suffix icon component
   */
  suffixIcon: {
    type: Object,
    default: null,
  },
  /**
   * @description native button type
   */
  nativeType: {
    type: String,
    values: buttonNativeTypes,
    default: 'button',
  },
})

function mapDisable(disable: boolean) {
  return disable ? 'is-disabled' : ''
}
function mapRounded(round: boolean) {
  return round ? 'is-rounded' : ''
}
function mapClass(property: string) {
  return property ? `dj-button--${property}` : ''
}
function mapSizeIcon(size: string) {
  switch (size) {
    case 'large':
      return 24
    case 'default':
      return 20
    case 'small':
      return 16
  }
  return 20
}
</script>
<script lang="ts">
export const buttonTypes = ['accent', 'primary', 'secondary', 'tertiary', 'glass', 'warning', ''] as const
export const buttonSizes = ['', 'small', 'default', 'large', ''] as const
export const buttonSizeMap = {
  large: 44,
  default: 40,
  small: 36,
} as const
export const buttonNativeTypes = ['button', 'submit', 'reset'] as const
</script>

<template>
  <button class="dj-button" :disabled="disabled" :class="[mapDisable(disabled), mapClass(type), mapClass(size), mapRounded(rounded)]" v-bind="$attrs">
    <template v-if="!loading">
      <template v-if="icon">
        <DJIcon :size="mapSizeIcon(size)">
          <component :is="icon" />
        </DJIcon>
      </template>
      <template v-else>
        <template v-if="prefixIcon">
          <DJIcon class="mr-8" :size="mapSizeIcon(size)">
            <component :is="prefixIcon" />
          </DJIcon>
        </template>
        <slot></slot>
        <template v-if="suffixIcon">
          <DJIcon class="ml-8" :size="mapSizeIcon(size)">
            <component :is="suffixIcon" />
          </DJIcon>
        </template>
      </template>
    </template>
    <span v-else class="spinner-outer"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>
  </button>
</template>

<style lang="scss" scoped>
@import '~/assets/scss/src/_spinner.scss';
.dj-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  transition: 0.1s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  padding: 8px 10px;
  font-size: 16px;
  font-family: $font-family-proximanova-semibold;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  height: 40px;
  color: brandColor(gray, 0);
  background-color: brandColor(brand, 900);

  &.is-rounded {
    border-radius: 999px;
  }

  &.is-disabled,
  &.is-disabled:hover,
  &.is-disabled:focus {
    cursor: not-allowed;
    pointer-events: none;
    color: brandColor(gray, 700);
    background: brandColor(gray, 100);

    &.dj-button {
      &--primary,
      &--secondary,
      &--tertiary {
        color: brandColor(gray, 700);
        background: brandColor(gray, 300);
      }
    }
  }

  &:hover:not(.is-disabled),
  &:focus:not(.is-disabled) {
    background-color: brandColor(brand, 950);
  }

  &--primary {
    color: brandColor(gray, 0);
    background-color: brandColor(gray, 900);

    &:hover:not(.is-disabled),
    &:focus:not(.is-disabled) {
      background-color: brandColor(gray, 950);
    }
  }
  &--secondary {
    color: brandColor(gray, 950);
    /* TODO: Need update brand color */
    background-color: #efefef;
    border: 1px solid brandColor(gray, 300);

    &:hover:not(.is-disabled) {
      /* TODO: Need update brand color */
      background-color: brandColor(gray, 300);
      border: 1px solid brandColor(gray, 300);
    }
    &:focus:not(.is-disabled) {
      background-color: brandColor(gray, 300);
    }
  }
  &--tertiary {
    color: brandColor(gray, 950);
    background-color: brandColor(gray, 0);
    /* TODO: Need update brand color */
    border: 1px solid #efefef;

    &:hover:not(.is-disabled) {
      color: brandColor(gray, 950);
      background-color: brandColor(gray, 0);
    }
    &:focus:not(.is-disabled) {
      background-color: brandColor(gray, 50);
    }
  }
  &--warning {
    color: brandColor(gray, 0);
    background-color: brandColor(yellow, 500);

    &:hover:not(.is-disabled),
    &:focus:not(.is-disabled) {
      background-color: brandColor(yellow, 600);
    }
  }
  &--glass {
    color: brandColor(gray, 0);
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.12);

    &:hover:not(.is-disabled),
    &:focus:not(.is-disabled) {
      color: brandColor(gray, 0);
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(10px);
    }
  }

  &--large {
    height: 44px;
    padding: 10px;
  }

  &--small {
    height: 36px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
